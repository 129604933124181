export function ignoreTouchesWhenSwiping(gallery: HTMLElement) {
  let startX = 0
  gallery.addEventListener(
    'touchstart',
    (e) => {
      startX = e.touches[0].clientX
    },
    { passive: true },
  )
  gallery.addEventListener(
    'touchmove',
    (e) => {
      if (Math.abs(e.touches[0].clientX - startX) > 5 && e.cancelable) {
        e.preventDefault()
      }
    },
    { passive: false },
  )
}
