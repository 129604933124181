
import { Route } from 'vue-router'
import { PitchupPhoto } from '~/shared/photos/types'
import { ignoreTouchesWhenSwiping } from '~/utility/flickity'
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator'
import { capitalize } from '~/utility/text'
import { logger } from '../../../utility/logger'
import { Photo } from '../../search/schemas'
import { photoCaptionAltFormatter } from '~/utility/photoCaptionAltFormatter'

@Component
export default class InlineGallery extends Vue {
  $refs!: { flickity: any }

  @Prop({ required: true })
    primaryPhoto: Photo

  @Prop({ required: true })
    photos: PitchupPhoto[]

  @Prop({ required: false })
    photoCount: number

  @Prop()
    searchResultIndex: number

  @Prop({ default: false })
    isSearchResults: boolean

  @Prop({ required: false })
    galleryInitialIndex: number

  @Prop({ required: false })
    useHash: boolean

  get galleryPhotos() {
    // ensure primary is first
    const photos: PitchupPhoto[] = []
    if (this.primaryPhoto) {
      photos.push(this.primaryPhoto)
    }
    (this.photos || []).forEach((photo) => {
      if (
        this.isDifferentPhoto(photo.url.masterImage, photos[0].url.masterImage)
      )
        photos.push(photo)
    })
    // until we are mounted, only put 1 photo into the dom
    return this.isVisible ? photos : photos.slice(0, 10)
  }

  isDifferentPhoto(firstUrl: string, secondUrl: string) {
    const firstPhoto = firstUrl.split('/')
    const seconPhoto = secondUrl.split('/')
    return (
      firstPhoto[firstPhoto.length - 1] != seconPhoto[seconPhoto.length - 1]
    )
  }

  @Prop({ default: false })
    fullscreen!: boolean

  @Prop({ default: false })
    fullscreenOpen: boolean

  currentImage: number = 1

  get flickityOptions() {
    return {
      initialIndex: this.currentImage - 1,
      dragThreshold: 20,
      pageDots: false,
      wrapAround: true,
      cellSelector: '.carousel-cell',
      selectedAttraction: 0.2,
      friction: 0.8,
      fullscreen: this.fullscreen,
      lazyLoad: 1,
      prevNextButtons: this.$isDesktop,
      draggable: !this.$isDesktop,
    }
  }

  gallery: any = null
  caption: any = null
  isFullScreen = false
  selectedImage: any = null
  galleryInUse = false
  dragging = false
  animationTimer: any = null
  viewFullscreenButton: HTMLElement | null = null
  exitFullscreenButton: HTMLElement | null = null

  mounted() {
    if (this.galleryInitialIndex) {
      this.currentImage = this.galleryInitialIndex + 1
    }

    if (this.fullscreen) {
      require('~/utility/pu-flickity-fullscreen')
    }
  }

  galleryClick(event) {
    if (this.$isDesktop && !this.fullscreen) {
      if (
        event.srcElement.tagName === 'svg' ||
        event.srcElement.tagName === 'path' ||
        event.srcElement.tagName === 'BUTTON'
      ) {
        event.stopPropagation()
        event.preventDefault()
      }
    }
  }

  getSizesAttribute() {
    if (this.$isDesktop && !this.isFullScreen) {
      return this.isSearchResults ? '25vw' : '45vw'
    } else {
      return '100vw'
    }
  }

  getImgAttrs(photo: PitchupPhoto, index: number) {
    const common = {
      sizes: this.getSizesAttribute(),
      width: '428',
      height: '321',
    }
    const srcset =
      this.$isDesktop && this.isFullScreen
        ? `/_cfi/cdn-cgi/image/format=auto,fit=cover,quality=30,w=720,h=540${photo.url.masterImage} 720w, /_cfi/cdn-cgi/image/format=auto,fit=cover,quality=50,w=1080,h=810${photo.url.masterImage} 1080w, /_cfi/cdn-cgi/image/format=auto,fit=scale-down,quality=50,w=1800,h=1350${photo.url.masterImage} 1800w`
        : `/_cfi/cdn-cgi/image/format=auto,fit=cover,quality=80,w=360,h=270${photo.url.masterImage} 360w, /_cfi/cdn-cgi/image/format=auto,fit=cover,quality=30,w=720,h=540${photo.url.masterImage} 720w, /_cfi/cdn-cgi/image/format=auto,fit=cover,quality=30,w=1080,h=810${photo.url.masterImage} 1080w`

    if (this.searchResultIndex !== 0 || index !== 0) {
      return {
        src: `/_cfi/cdn-cgi/image/format=auto,fit=cover,quality=80,w=360,h=270${photo.url.masterImage}`,
        srcset: srcset,
        ...common,
      }
    }
    return {
      src: `/_cfi/cdn-cgi/image/format=auto,fit=cover,quality=80,w=360,h=270${photo.url.masterImage}`,
      srcset: srcset,
      fetchpriority: 'high',
      decoding: 'sync',
      ...common,
    }
  }

  showFlickity = false

  initGallery() {
    this.gallery = this.$el
    this.caption = this.gallery.querySelector('.caption')
    this.initHandlers()
    const flickity = this.$refs.flickity.$flickity
    this.viewFullscreenButton = flickity.viewFullscreenButton?.element
    this.exitFullscreenButton = flickity.exitFullscreenButton?.element
    if (this.viewFullscreenButton) {
      this.viewFullscreenButton?.addEventListener('click', this.openFullscreen)
    }
    if (this.$route.hash === `#${this.fullscreenHash}` || this.fullscreenOpen) {
      this.visibilityChanged(true)
      this.launchFullscreenGallery()
    }
    flickity.resize()
    this.$nextTick(() => {
      this.showFlickity = true
      this.$emit('gallery-loaded')
    })
  }

  initHandlers() {
    const f = this.$refs.flickity
    f.on('change', () => this.updateSlideStatus())
    f.on('select', () => this.updateCaption())
    f.on('fullscreenChange', (fullscreenStatus: boolean) =>
      this.fullscreenChange(fullscreenStatus),
    )
    f.on('dragStart', () => this.dragStart())
    f.on('dragEnd', () => this.dragEnd())
    f.once('dragStart', () => {
      this.$emit('user-has-interacted')
    })
    ignoreTouchesWhenSwiping(this.gallery)
    f.on('staticClick', (event) => {
      if (event.target.tagName !== 'IMG') {
        this.closeFullscreen()
      }
    })
  }

  fullscreenChange(fullscreenStatus: boolean) {
    logger('fullscreenChange', fullscreenStatus)
    this.isFullScreen = fullscreenStatus
    Vue.nextTick(() => {
      this.reactivateFlickity()
      this.updateCaption()
      this.updateSlideStatus()
      this.isFullScreen
        ? this.gallery.setAttribute('gallery-open', 'true')
        : this.gallery.removeAttribute('gallery-open')

      if (this.isFullScreen) {
        this.exitFullscreenButton?.addEventListener(
          'click',
          this.closeFullscreen,
        )
      }
    })
    if (!fullscreenStatus) {
      this.$emit('fullscreen-closed')
    }
  }

  reactivateFlickity() {
    if (this.isFullScreen) {
      document.documentElement.classList.add('is-flickity-fullscreen')
    }
  }

  fullscreenHash = 'photo-gallery'

  @Watch('$route')
  routeChange(to: Route, from?: Route) {
    if (to.path === from?.path) {
      to.meta!.scroll = false
    }
    const galleryHash = `#${this.fullscreenHash}`
    if (to.hash === galleryHash) {
      this.launchFullscreenGallery()
    } else if (from?.hash === galleryHash && !to.hash.includes(galleryHash)) {
      this.closeFullscreenGallery()
    }
  }

  @Watch('photos')
  photosChange(_, oldPhotos) {
    // When photos prop is updated and images are added to DOM via loop
    // in template this will add extra photos to flickity.
    this.$nextTick(() => {
      if (this.$refs['flickity-photos']?.length > oldPhotos?.length) {
        this.$flickity?.append(
          this.$refs['flickity-photos'].slice(oldPhotos.length),
        )
      }
    })
  }

  openFullscreen() {
    logger('openFullscreen', this.useHash)
    void this.$router.push({
      hash: this.fullscreenHash,
      query: this.$router.currentRoute.query,
    })
  }

  closeFullscreen() {
    logger('closeFullscreen', this.useHash)
    if (this.useHash) {
      void (this.$routerClient.getReferrer()
        ? this.$router.back()
        : this.$router.push({
          query: this.$router.currentRoute.query,
        }))
    } else {
      this.closeFullscreenGallery()
    }
  }

  get $flickity() {
    if (this.$refs && this.$refs.flickity && this.$refs.flickity.$flickity) {
      return this.$refs.flickity.$flickity
    }
    return undefined
  }

  launchFullscreenGallery() {
    if (this.fullscreen && this.gallery) {
      this.$flickity?.viewFullscreen()
    }
  }

  closeFullscreenGallery() {
    logger('closeFullscreenGallery')
    this.$flickity?.exitFullscreen()
  }

  updateSlideStatus() {
    const slideIndex = this.$refs.flickity.selectedIndex() + 1
    this.currentImage = slideIndex
    this.$emit('slide-changed', this.currentImage)
  }

  updateCaption() {
    this.selectedImage = this.gallery.querySelector(
      '.carousel-cell.is-selected img',
    )
    if (this.isFullScreen && this.caption && this.selectedImage) {
      this.caption.textContent = this.selectedImage.getAttribute('data-caption')
    }
  }

  dragStart() {
    clearTimeout(this.animationTimer)
    this.galleryInUse = true
    this.dragging = true
    document.body.classList.add('user-dragging-gallery')
  }

  dragEnd() {
    this.dragging = false
    document.body.classList.remove('user-dragging-gallery')
    this.animationTimer = setTimeout(() => {
      this.galleryInUse = false
    }, 3000)
  }

  capitalize(value: string) {
    return capitalize(value)
  }

  photoCaptionAltFormatter(caption) {
    return photoCaptionAltFormatter(caption)
  }

  isVisible = false
  visibilityChanged(isVisible: boolean) {
    this.isVisible = isVisible
  }
}
