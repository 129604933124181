var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator';
var Puflickity = (_dec = Prop(), _dec2 = Prop({
  default: true
}), _dec3 = Watch('start'), Component(_class = (_class2 = class Puflickity extends Vue {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "options", _descriptor, this);
    _initializerDefineProperty(this, "start", _descriptor2, this);
    this.hasDoneInit = false;
    this.$flickity = null;
  }
  mounted() {
    if (this.start) {
      this.$nextTick(() => {
        this.initFlickity();
      });
    }
  }
  beforeDestroy() {
    if (this.$flickity) {
      this.$flickity.destroy();
      this.$flickity = null;
    }
  }
  render(h) {
    return h('div', this.$slots.default);
  }
  initChanged(start) {
    if (start) {
      this.$nextTick(() => {
        this.initFlickity();
      });
    }
  }

  /**
   * Initialize a new flickity and emit init event.
   */
  initFlickity() {
    if (this.hasDoneInit) {
      return;
    }
    this.hasDoneInit = true;
    this.importFlickity().then(Flickity => {
      this.$flickity = new Flickity(this.$el, this.options);
      this.$emit('init', this.$flickity);
    });
  }
  importFlickity() {
    if (process.client) {
      return import( /* webpackChunkName: "flickity", webpackMode: "lazy" */'flickity').then(Flickity => Flickity.default || Flickity);
    } else {
      return Promise.resolve(null);
    }
  }

  /**
   * Return the current flickity instance to access directly
   *
   * @return {Flickity}
   */
  flickity() {
    return this.$flickity;
  }

  /**
   * Selecting Slides
   */

  /**
   * Select a slide
   *
   * @param {number} index
   * @param {boolean} isWrapped
   * @param {boolean} isInstant
   */
  select(index, isWrapped, isInstant) {
    this.$flickity.select(index, isWrapped, isInstant);
  }

  /**
   * Change to the next slide
   *
   * @param {boolean} isWrapped
   * @param {boolean} isInstant
   */
  next(isWrapped, isInstant) {
    this.$flickity.next(isWrapped, isInstant);
  }

  /**
   * Change to the previous slide
   *
   * @param {boolean} isWrapped
   * @param {boolean} isInstant
   */
  previous(isWrapped, isInstant) {
    this.$flickity.previous(isWrapped, isInstant);
  }

  /**
   * Select a cell
   *
   * @param {number} value
   * @param {boolean} isWrapped
   * @param {boolean} isInstant
   */
  selectCell(value, isWrapped, isInstant) {
    this.$flickity.selectCell(value, isWrapped, isInstant);
  }

  /**
   * Sizing and Positioning
   */

  /**
   * Trigger a resize event
   */
  resize() {
    this.$flickity.resize();
  }

  /**
   * Trigger a reposition event
   */
  reposition() {
    this.$flickity.reposition();
  }

  /**
   * Adding and removing cells
   */

  /**
   * Prepend elements to flickity
   *
   * @param {array|HTMLElement|NodeList} elements
   */
  prepend(elements) {
    this.$flickity.prepend(elements);
  }

  /**
   * Append elements to flickity
   *
   * @param {array|HTMLElement|NodeList} elements
   */
  append(elements) {
    this.$flickity.append(elements);
  }

  /**
   * Insert elements at a given index
   *
   * @param {array|HTMLElement|NodeList} elements
   * @param {number} index
   */
  insert(elements, index) {
    this.$flickity.insert(elements, index);
  }

  /**
   * Remove elements from flickity
   *
   * @param {array|HTMLElement|NodeList} elements
   */
  remove(elements) {
    this.$flickity.remove(elements);
  }

  /**
   * Player
   */

  /**
   * Trigger a playPlayer event
   */
  playPlayer() {
    this.$flickity.playPlayer();
  }

  /**
   * Trigger a stopPlayer event
   */
  stopPlayer() {
    this.$flickity.stopPlayer();
  }

  /**
   * Trigger a pausePlayer event
   */
  pausePlayer() {
    this.$flickity.pausePlayer();
  }

  /**
   * Trigger a unpausePlayer event
   */
  unpausePlayer() {
    this.$flickity.unpausePlayer();
  }

  /**
   * Trigger a rerender event
   */
  rerender() {
    this.$flickity.destroy();
    this.initFlickity();
  }

  /**
   * Utilities
   */

  /**
   * Destroy the flickity instance
   */
  destroy() {
    this.$flickity.destroy();
  }

  /**
   * Trigger a rerender event
   */
  reloadCells() {
    this.$flickity.reloadCells();
  }

  /**
   * Get the cell elements
   *
   * @return {array}
   */
  getCellElements() {
    return this.$flickity.getCellElements();
  }

  // /**
  //  * Return flickity data
  //  *
  //  * @return {Flickity}
  //  */
  // data() {
  //   const Flickity = require('flickity');

  //   return Flickity.data(this.$el);
  // }

  /**
   * Events
   */

  /**
   * Attach an event
   *
   * @param {string} eventName
   * @param {function} listener
   */
  on(eventName, listener) {
    this.$flickity.on(eventName, listener);
  }

  /**
   * Remove an event
   *
   * @param {string} eventName
   * @param {function} listener
   */
  off(eventName, listener) {
    this.$flickity.off(eventName, listener);
  }

  /**
   * Attach an event once
   *
   * @param {string} eventName
   * @param {function} listener
   */
  once(eventName, listener) {
    this.$flickity.once(eventName, listener);
  }

  /**
   * Properties
   */

  /**
   * Return the selected element
   *
   * @return {HTMLElement}
   */
  selectedElement() {
    return this.$flickity.selectedElement;
  }

  /**
   * Return the selected elements
   *
   * @return {array}
   */
  selectedElements() {
    return this.$flickity.selectedElements;
  }

  /**
   * Return the selected index
   *
   * @return {number}
   */
  selectedIndex() {
    return this.$flickity.selectedIndex;
  }

  /**
   * Return the cells
   *
   * @return {array}
   */
  cells() {
    return this.$flickity.cells;
  }

  /**
   * Return the slides
   *
   * @return {array}
   */
  slides() {
    return this.$flickity.slides;
  }

  /**
   * Disable dragging of slider
   */
  disableDrag() {
    this.$flickity.options.draggable = false;
    this.$flickity.updateDraggable();
  }

  /**
   * Enable dragging of slider
   */
  enableDrag() {
    this.$flickity.options.draggable = true;
    this.$flickity.updateDraggable();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "options", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "start", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "initChanged", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "initChanged"), _class2.prototype)), _class2)) || _class);
export { Puflickity as default };